import { queryKey, type Merge, type Optional, type Timestamp } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ACCOUNT } from './query-keys';
import type { Get, Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';
export type ReviewStatus = 'ignored' | 'reviewed' | 'denied' | 'deferred';

export const ALL_INDUSTRIES = [
	'beauty',
	'clothing',
	'electronics',
	'furniture',
	'handcrafts',
	'jewelry',
	'painting',
	'photography',
	'restaurants',
	'groceries',
	'other_food_drink',
	'sports',
	'toys',
	'services',
	'virtual_services',
	'other',
	'do_not_know'
] as const;
export type Industry = (typeof ALL_INDUSTRIES)[number];

export const ALL_COMPANY_SIZES = ['one', 'less_than_five', 'less_than_ten', 'eleven_to_twenty_five', 'more_than_twenty_five'] as const;
export type CompanySize = (typeof ALL_COMPANY_SIZES)[number];

export type AccountSurvey = {
	contactName: string;
	contactEmail: string;
	industry: Industry;
	companySize: CompanySize;
};

export type Account = {
	id: number;
	shop: string;
	domain: string;
	countryCode: string;
	timezone: string;
	currency: string;
	primaryLocale: string;
	platformPlan: string;
	ownerName: string;
	ownerEmail: string;
	userHash: string;
	syncToken: string;
	supportUserHash: string;
	phone: string;
	name: string;
	billingStatus: AccountBillingStatus;
	reviewRating: number;
	reviewStatus: ReviewStatus;
	accountSurvey?: AccountSurvey;
	isCanvaConnected: boolean;
	createdAt: Date;
	updatedAt: Date;
};

export type DehydratedAccount = Merge<
	Account,
	{
		createdAt: Timestamp;
		updatedAt: Timestamp;
	}
>;

const hydrateAccount = (account?: DehydratedAccount): Optional<Account> => {
	return account
		? {
				...account,
				createdAt: new Date(account.createdAt),
				updatedAt: new Date(account.updatedAt)
			}
		: undefined;
};

export function useAccountFetch(load = false) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedAccount>;
	const {
		data: account,
		refetch: fetchAccount,
		isFetching: isLoading
	} = useQuery({
		queryKey: queryKey(ACCOUNT),
		queryFn: () => get('/auth/whoami').then(hydrateAccount),
		enabled: load
	});
	return { account, fetchAccount, isLoading };
}

export function useAccount() {
	const { account } = useAccountFetch();

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

export function useSetAccount() {
	const client = useQueryClient();
	const setAccount = useCallback((account: DehydratedAccount) => client.setQueryData(queryKey(ACCOUNT), hydrateAccount(account)), [client]);
	return setAccount;
}

export function useReviewRating() {
	const { post } = useAuthenticatedFetch() as Post<any, DehydratedAccount>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const { mutate: setReviewRating, isPending: isSetReviewRatingLoading } = useMutation({
		mutationFn: (reviewRating: number) => post('/account/review-rating', { rating: reviewRating }),
		...setAccountOption
	});

	const { mutate: setReviewStatus, isPending: isSetReviewStatusLoading } = useMutation({
		mutationFn: (reviewStatus: ReviewStatus) => post('/account/review-status', { reviewStatus }),
		...setAccountOption
	});

	return {
		setReviewRating: (reviewRating: number) => setReviewRating(reviewRating),
		isSetReviewRatingLoading,
		setReviewStatus: (reviewStatus: ReviewStatus) => setReviewStatus(reviewStatus),
		isSetReviewStatusLoading
	};
}

export function useAccountSurvey() {
	const { post } = useAuthenticatedFetch() as Post<AccountSurvey, DehydratedAccount>;
	const setAccount = useSetAccount();

	const { mutate: submitAccountSurvey, isPending: isLoading } = useMutation({
		mutationFn: (survey: AccountSurvey) => post('/account/survey', survey),
		onSuccess: setAccount
	});

	return {
		submitAccountSurvey,
		isLoading
	};
}
